<template>
  <div>

    <div class="rules-content" :class="{'border-left-yes-for-rules': border}" style="margin-left: 20px; padding-bottom: 10px" :style="{'padding-left': border ? '8px' : '10px', }">

      <div class="d-flex align-items-center">
        <div class="rules border-with-before box-shadow" @click="getRule(rules)">
          <span>Правила (0)</span>
          <span v-if="boolAddRules(rules)">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
            </svg>
          </span>
          <span v-else>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-up" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/>
            </svg>
          </span>
        </div>

        <div class="trash-rules box-shadow" @click="trashRule(parentRule, index)">
          <!-- <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-x-circle" viewBox="0 0 16 16">
            <path fill="#333" d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
            <path fill="#333" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
          </svg> -->

          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-dash" viewBox="0 0 16 16">
            <path fill="#000" d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
          </svg>
        </div>
      </div>

      <div class="view-show " v-if="boolAddRules(rules)">
        <div class="border-left-yes">
          <div class="rules-add-conditions box-shadow" style="margin-left: 8px">
            <span class="d-100 d-h" v-if="rules[0].boolAndOr == 'and'" @click="boolRuleAndOr(rules)">И</span>
            <span class="d-100 d-h" v-if="rules[0].boolAndOr == 'or'" @click="boolRuleAndOr(rules)">ИЛИ</span>
             <span class="d-150 d-flex align-items-center div-margin-5">Условия
               <a-form-item>
                <a-switch v-model="rules[0].conditionsBool" v-decorator="['switch', { valuePropName: 'checked' }]"  @change="changeItem(rules)" />
              </a-form-item>
             </span>
             <span class="d-200 d-h" v-if="rules[0].addConditionsItem" @click="addCondition(rules)">
               Добавить условие
             </span>
             <span class="d-200 d-h" v-if="rules[0].addRulesItem" @click="addRule(rules)">
               Добавить группу правил
             </span>
          </div>
        </div>

        <div class="conditions border-with-before" style="margin-left: 30px" v-if="hasConditions(rules) && rules[0].addConditionsItem">
          <div class="d-flex width-100 align-items-end" v-for="(condition, conditionIndex) in rules[1].conditions">
            <div class="">
              <label for="exampleInputEmail1">Выберите условие</label>
              <a-form-item>
                <a-select v-model="condition.condition_name"  placeholder="Выберите условие" @change="changeConditons(condition, conditionIndex)">
                  <a-select-option v-for="(value, index) in conditions" :key="value.value" :value="value.value">
                    {{value.title}}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <div class="invalid-feedback" v-for="value in validateErrors.price_type"> {{value}} </div>
            </div>

            <div class=""  v-if="condition.condition_name != ''">
              <label for="">Значение</label>
              <template>
                <a-form-item
                  :validate-status = "condition.errorValue"
                >
                  <a-select
                    show-search
                    v-model="condition.value"
                    placeholder="Ваберите значение"
                    style="width: 300px"
                    option-filter-prop="children"
                    :filter-option="filterOption"
                    @blur="blurConditionValue(condition)"
                  >
                    <a-select-option v-for="(value, index) in conditionValue" :value="value.value">
                      {{value.title}}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </template>
            </div>

            <div class=""  v-if="condition.condition_name == 'country_from'">
              <label for="">Страна отправки</label>
              <template>
                <a-form-item
                  :validate-status = "condition.errorConditionItem"
                >
                  <a-select
                    show-search
                    v-model="condition.country_from"
                    placeholder="Выберите страну"
                    style="width: 300px"
                    option-filter-prop="children"
                    :filter-option="filterOption"
                    @blur="blurConditionItem(condition)"
                    @search="searchCountriesForConditions"
                  >
                    <a-select-option v-for="(value, index) in countries" :value="value.id">
                      {{value.title}}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </template>
            </div>
            <div class=""  v-if="condition.condition_name == 'country_to'">
              <label for="">Страна доставки</label>
              <template>
                <a-form-item
                  :validate-status = "condition.errorConditionItem"
                >
                  <a-select
                    show-search
                    v-model="condition.country_to"
                    placeholder="Выберите страну"
                    style="width: 300px"
                    option-filter-prop="children"
                    :filter-option="filterOption"
                    @blur="blurConditionItem(condition)"
                    @search="searchCountriesForConditions"
                  >
                    <a-select-option v-for="(value, index) in countries" :value="value.id">
                      {{value.title}}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </template>
            </div>

            <div class=""  v-if="condition.condition_name == 'region_from'">
              <label for="">Область отправки</label>
              <template>
                <a-form-item
                  :validate-status = "condition.errorConditionItem"
                >
                  <a-select
                    show-search
                    v-model="condition.region_from"
                    placeholder="Выберите область"
                    style="width: 300px"
                    option-filter-prop="children"
                    @blur="blurConditionItem(condition)"
                    :filter-option="filterOption"
                  >
                    <a-select-option v-for="(value, index) in regions" :value="value.id">
                      {{value.title}}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </template>
            </div>
            <div class=""  v-if="condition.condition_name == 'region_to'">
              <label for="">Область доставки</label>
              <template>
                <a-form-item
                  :validate-status = "condition.errorConditionItem"
                >
                  <a-select
                    show-search
                    v-model="condition.region_to"
                    placeholder="Выберите область"
                    style="width: 300px"
                    option-filter-prop="children"
                    @blur="blurConditionItem(condition)"
                    :filter-option="filterOption"
                  >
                    <a-select-option v-for="(value, index) in regions" :valuetitle="value.title" :value="value.id">
                      {{value.title}}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </template>
            </div>

            <div class=""  v-if="condition.condition_name == 'city_from'">
              <label for="">Район отправки</label>
              <template>
                <a-form-item
                  :validate-status = "condition.errorConditionItem"
                >
                  <a-select
                    show-search
                    v-model="condition.city_from"
                    placeholder="Выберите район"
                    style="width: 300px"
                    option-filter-prop="children"
                    @blur="blurConditionItem(condition)"
                    :filter-option="filterOption"
                  >
                    <a-select-option v-for="(value, index) in cities" :value="value.id">
                      {{value.title}}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </template>
            </div>
            <div class=""  v-if="condition.condition_name == 'city_to'">
              <label for="">Район доставки</label>
              <template>
                <a-form-item
                  :validate-status = "condition.errorConditionItem"
                >
                  <a-select
                    show-search
                    v-model="condition.city_to"
                    placeholder="Выберите район"
                    style="width: 300px"
                    option-filter-prop="children"
                    @blur="blurConditionItem(condition)"
                    :filter-option="filterOption"
                  >
                    <a-select-option v-for="(value, index) in cities" :value="value.id">
                      {{value.title}}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </template>
            </div>

            <div class=""  v-if="condition.condition_name == 'branchoffice_from'">
              <label for="">Склад для забора</label>
              <template>
                <a-form-item
                  :validate-status = "condition.errorConditionItem"
                >
                  <a-select
                    show-search
                    v-model="condition.branchoffice_from"
                    placeholder="Выберите офис"
                    style="width: 300px"
                    option-filter-prop="children"
                    @blur="blurConditionItem(condition)"
                    :filter-option="filterOption"
                  >
                    <a-select-option v-for="(value, index) in branches" :value="value.id">
                      {{value.title}}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </template>
            </div>
            <div class=""  v-if="condition.condition_name == 'branchoffice_to'">
              <label for="">Конечный склад</label>
              <template>
                <a-form-item
                  :validate-status = "condition.errorConditionItem"
                >
                  <a-select
                    show-search
                    v-model="condition.branchoffice_to"
                    placeholder="Выберите офис"
                    style="width: 300px"
                    option-filter-prop="children"
                    @blur="blurConditionItem(condition)"
                    :filter-option="filterOption"
                  >
                    <a-select-option v-for="(value, index) in branches" :value="value.id">
                      {{value.title}}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </template>
            </div>

            <div class=""  v-if="condition.condition_name == 'client'">
              <label for="">Клиент</label>
              <template>
                <a-form-item
                  :validate-status = "condition.errorConditionItem"
                >
                  <a-select
                    show-search
                    v-model="condition.client"
                    placeholder="Выберите клиента"
                    style="width: 300px"
                    option-filter-prop="children"
                    @blur="blurConditionItem(condition)"
                    :filter-option="filterOption"
                  >
                    <a-select-option v-for="(value, index) in clients" :value="value.id">
                      {{value.name}}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </template>
            </div>

            <section class="trash-conditions box-shadow" @click="trashCondition(rules[1].conditions, conditionIndex)" v-if="condition.condition_name != ''">
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-dash" viewBox="0 0 16 16">
                <path fill="#000" d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
              </svg>
            </section>
          </div>
        </div>

        <div v-if="rules.length > 1 && !rules[0].conditionsBool">
          <div v-for="(rule, index) in rules">
            <Rules v-if="index != 0 && rule.rules"
              :border="borderLeftYes(rules, index)"
              :rules="rule.rules"
              :index="index"
              :branches="branches"
              :typeOfService="typeOfService"
              :parentRule="rules"
              :countries="countries"
              :regions="regions"
              :cities="cities"
              :clients="clients"
              :conditionValue="conditionValue"
              :conditions="conditions"
              :errorCount="errorCount"
            />
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Rules from '@/views/tariffs/components/Rules'

export default {
  name: 'Rules',
  props: ['rules', 'index', 'border', 'branches', 'typeOfService', 'parentRule', 'countries', 'regions', 'cities', 'clients', 'conditionValue', 'conditions', 'errorCount'],
  data(){
    return {
      validateErrors: [],


      priceType: [
        {
          title: 'Линейный',
          value: 'linear'
        },
        {
          title: 'Не линейный',
          value: 'unlinear'
        }
      ],
    }
  },
  components: {
    Rules
  },
  mounted(){
    setInterval(() => {this.$forceUpdate()}, 3000)
  },
  methods: {
    blurConditionValue(value){
      console.log(this.rules)
      if(!value.value){
        value.errorValue = 'error';
        console.log(value)
        this.$forceUpdate();
      }else{
        value.errorValue = '';
        this.$forceUpdate();
      }
    },
    blurConditionItem(value){
      if(!value[value.condition_name]){
        value.errorConditionItem = 'error';
        this.$forceUpdate();
      }else{
        value.errorConditionItem = '';
        this.$forceUpdate();
      }
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    searchCountriesForConditions(value){
      axios.get('/international/countries/autocomplete/search', {params: {query: value}}).then(response => {
        if(response.status == 200){
          this.countries = response.data;
        }else if(response.status == 204){
          this.$message.error(
                'Нет соответствующих стран',
                3,
          );
        }
      }).catch(error => {
        if(error.response.status == 204){
          this.$message.error(
                'Нет соответствующих стран',
                3,
          );
        }else if(error.response.status == 500){
          this.$message.error(
                'Ошибка сервера',
                3,
          );
        }
      })
    },
    searchRegionsForConditions(value){
      axios.post('/regions/autocomplete/search/with/paginate', this.dataFilter, {params: {query: value}}).then(response => {
        if(response.status == 200){
          this.regions = response.data.data;
          console.log(this.regions)
        }else if(response.status == 204){
          this.$message.error(
                'Нет соответствующих стран',
                3,
          );
        }
      }).catch(error => {
        if(error.response.status == 204){
          this.$message.error(
                'Нет соответствующих стран',
                3,
          );
        }else if(error.response.status == 500){
          this.$message.error(
                'Ошибка сервера',
                3,
          );
        }
      })
    },
    trashCondition(conditions, conditionIndex){
      conditions.splice(conditionIndex, 1);
    },
    changeConditons(condition, conditionIndex){

    },
    boolRuleAndOr(rules){
      if(rules[0].boolAndOr == 'and'){
        rules[0].boolAndOr = 'or'
      }else{
        rules[0].boolAndOr = 'and'
      }
    },
    trashRule(rules, index){
      rules.splice(index, 1);
    },
    addCondition(rules){
      let conditionArrayFull = {conditions: [{condition_name: ''}]}
      let conditionArray = [{condition_name: ''}];
      let condition = {condition_name: ''};
      if(rules[1]){
        if(rules[1].conditions){
          rules[1].conditions.push(condition);
          this.$forceUpdate()
        }else{
          rules[1].conditions = conditionArray;
          this.$forceUpdate()
        }
      }else{
        rules.push(conditionArrayFull);
        this.$forceUpdate()
      }
    },
    addRule(rules){
      let rule = {
        rules: [{
          showRules: false,
          addRulesItem: true,
          addConditionsItem: false,
          conditionsBool: false,
          boolAndOr: 'and'
        }]
      };
      rules.push(rule);
      this.$forceUpdate();
    },
    changeItem(rules){
      rules[0].addRulesItem = !rules[0].addRulesItem;
      rules[0].addConditionsItem = !rules[0].addConditionsItem;
    },
    borderLeftYes(rules, index){
      if(rules[index+1]){
        return 1
      }else{
        return 0
      }
    },
    getRule(rules){
      rules[0].showRules = !rules[0].showRules
      return rules[0].showRules;
    },
    hasConditions(rules){
      if(rules.length>=2){
        if(rules[1].conditions){
          return 1;
        }else{
          return 0;
        }
      }
    },
    getChildRules(rule){
      if(rule){
        return 1;
      }
    },
    functionAddRules(rules, index){
      rules[index].showRules = !rules[index].showRules
      return rules[index].showRules;
    },
    boolAddRules(rules){
      return rules[0].showRules
    },
  },
}
</script>
<style>
  .trash-rules{
    background: #fff;
    padding: 5px;
    margin: 10px 10px 10px 10px;
    border-radius: 50%;
    cursor: pointer;
  }
</style>
